import React from 'react'
import Layout from '../components/layout/Layout'
import taken from '../images/taken.svg'

const NotFoundPage = () => (
  <Layout>
    <section className="section text-center">
      <h1>NOT FOUND</h1>
      <p>
        The page you are looking for doesn&#39;t exist... Please use the links above to navigate to the page you want.
      </p>
      <img src={taken} alt="Not Found" />
    </section>
  </Layout>
)

export default NotFoundPage
